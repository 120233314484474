body.is-preload #banner h2 {
  opacity: 1;
  transform: none;
}

body.is-preload #banner h2:before, body.is-preload #banner h2:after {
  width: 100%;
}

body.is-preload #banner .more {
  opacity: 1;
  transform: none;
}

body.is-preload #banner:after {
  opacity: 0;
}

/*# sourceMappingURL=index.619db872.css.map */
