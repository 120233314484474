/*
	Spectral by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Banner */

	body.is-preload #banner h2 {
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		opacity: 1;
	}

		body.is-preload #banner h2:before, body.is-preload #banner h2:after {
			width: 100%;
		}

	body.is-preload #banner .more {
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		opacity: 1;
	}

	body.is-preload #banner:after {
		opacity: 0;
	}